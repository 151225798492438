<template lang="pug">
    .main-wrapper.feriados-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='back()')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "caixas" }'> Feriados do Estabelecimento</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } procedimento`")
                        .p-grid.p-fluid.p-align-end(style='margin: 0 auto; max-width: 500px;')

                            .p-col-12
                                label.form-label Estabelecimento:
                                InputText(type='text' :disabled="true" v-model='nm_estabelecimento.nm_fantasia')

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_feriado.$error }")
                                label.form-label Descrição:
                                InputText(type='text' v-model='$v.model.ds_feriado.$model')
                                .feedback--errors(v-if='submitted && $v.model.ds_feriado.$error')
                                    .form-message--error(v-if="!$v.model.ds_feriado.required") <b>Procedimento</b> é obrigatório.

                            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.dt_feriado.$error }")
                                label.form-label Data do Feriado
                                .p-inputgroup
                                    Calendar( v-model='$v.model.dt_feriado.$model' dateFormat="dd/mm/yy" :locale="ptbr"
                                        :manualInput='false')
                                    Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='model.dt_feriado = null;')
                                .feedback--errors(v-if='submitted && $v.model.dt_feriado.$error')
                                    .form-message--error(v-if="!$v.model.dt_feriado.required") <b>Data inicial</b> é obrigatório.

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ie_nivel.$error }")
                                label.form-label Tipo:
                                Dropdown(v-model='$v.model.ie_nivel.$model' :options='options.nivel'
                                        dataKey='id' optionLabel='label' optionValue='value' placeholder='- Selecione -' )
                                .feedback--errors(v-if='submitted && $v.model.ie_nivel.$error')
                                    .form-message--error(v-if="!$v.model.ie_nivel.required") <b>Tipo de Procedimento</b> é obrigatório.
                            
                            .p-col-6
                                span <b>Usuario que criou: </b> {{ model.nm_usuario_cri }}
                            .p-col-6
                                span <b>Data da criação: </b> {{ model.dt_criado_f }}
                            .p-col-6
                                span <b>Usuario que editou: </b> {{ model.nm_usuario_edi }}
                            .p-col-6
                                span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12.ta-center.mt-2
                                Button(label='Salvar' type="submit" style='max-width:120px')
                
</template>

<style lang="scss">
    .feriados-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .textarea-informacoes,
        .textarea-preparo {
            height: 200px !important;
            overflow-y: auto !important;
            resize: none;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import InputSwitch from 'primevue/inputswitch'
    import Textarea from 'primevue/textarea'
    import Calendar from 'primevue/calendar'
    import moment from 'moment'

    import { Feriados, Estabelecimento } from './../../middleware'
    import { pCalendarLocale_ptbr } from './../../utils'
    import { required } from 'vuelidate/lib/validators'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Calendar,
                InputMask, SelectButton, Dropdown, Textarea, Tooltip, InputSwitch
        },
        directives: { tooltip: Tooltip },
        created () {
            let id = 0
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id != 0) {

                Feriados.find(id).then(response => {
                    if (response.status == 200) {
                        console.log(response.data);
                        let keys = Object.keys(this.model)
                        
                        keys.forEach(key => this.model[key] = response.data[key])
                        
                        this.model.id = id
                        this.model.nm_usuario_cri = response.data['nm_usuario_cri']
                        this.model.nm_usuario_edi = response.data['nm_usuario_edi']
                        this.model.dt_feriado = moment(this.model.dt_feriado)._d
                        this.model.dt_criado_f = moment(response.data['dt_criado']).format('DD/MM/YYYY')
                        if (response.data['dt_atualizado'] != undefined){
                            this.model.dt_atualizado_f = moment(response.data['dt_atualizado']).format('DD/MM/YYYY')
                        }

                    }
                    this.waiting = false
                })
            } else {
                let usuario = JSON.parse(localStorage.getItem('usuario'))
                this.nm_estabelecimento = usuario.estabelecimentos[usuario.idxCurrentEstabelecimento]
                Estabelecimento.findAll().then(response => {
                    if (response.status == 200) {
                        this.options.estabelecimentos = response.data
                    }
                    this.waiting = false
                })
            }   
        },
        data () {
            return {
                ptbr: pCalendarLocale_ptbr,
                windowInnerWidth: window.innerWidth,
                model: {
                    ds_feriado: '',
                    dt_feriado: '',
                    ie_nivel: '',
                },
                options: {
                    estabelecimentos: [],
                    nivel: [
                        { label: '- Selecione', value: '' },
                        { label: 'Municipal', value: 'MU' },
                        { label: 'Estadual', value: 'ES' },
                        { label: 'Nacional', value: 'NA' }
                    ]
                },
                nm_estabelecimento:'',
                waiting: true,
                waitingForm: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    ds_feriado: { required },
                    dt_feriado: { required },
                    ie_nivel: { required }
                }
            }
            
            return v
        },
        methods: {
            handleSubmit () {
                this.submitted = true
                
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)
                dataSend.dt_feriado = moment(dataSend.dt_feriado,'DD-MM-YYYY').format('YYYY-MM-DD')

                if (! dataSend.id) {
                    delete dataSend.dt_atualizado
                    delete dataSend.dt_criado
                    delete dataSend.nm_usuario_edi
                    delete dataSend.nm_usuario_cri
                }
                
                this.waitingForm = true
                Feriados.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'feriados' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        console.log(response.data)
                        if (response.data.detail != undefined) {
                            if (typeof response.data.detail == 'string')
                                this.$toast.error(response.data.detail, { duration: 3000 })
                            else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        } else if (response.data.non_field_errors){
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        } else if ( typeof response.data === 'object'){
                            let keys = Object.keys(response.data)
                            keys.forEach(key => {
                             response.data[key].forEach(errorMsg => {
                                 this.$toast.error(errorMsg, { duration: 3000 })
                             })
                            })
                        }
                    }
                    this.waitingForm = false
                })
            },
            back () {
                this.$router.push(`/feriados/listar`)
            }
         }
      }
</script>